.Patient .Patient__Heading {
  margin-top: 25px;
}

.Patient .Patient__Heading .Patient__Heading__Detail {
  font-size: 18px;
  font-weight: normal;
}

.Patient .Patient__Heading .Patient__Heading__Detail--Left {
  border-left: 1px solid #ccc;
  margin-left: 0.5em;
  padding-left: 0.5em;
}

.Patient .Patient__Heading .Patient__Heading__Detail--Right {
  float: right;
}

.Patient .Patient__VisionTest .Patient__Title__Status {
  margin-left: 15px;
}

.Patient .Patient__VisionTest .Patient__VisionTestAcuity {
  background-color: #eef5fb;
}

.Patient .Patient_Italicized {
  font-size: 0.9rem;
  font-style: italic;
  font-weight: normal;
}

.Patient .Patient__VisionTestNotes--Heading {
  margin-top: calc(2rem -  0.14285714em);
}

.Patient .Patient__VisionTestPrescriptions--Heading {
  margin-top: calc(2rem -  0.14285714em);
}

.Patient .Patient__VisionTest__ManuallySubmittedBasePrescriptions__Status--missing {
  background-color: #FFF6F6;
  color: #9F3A37;
}
